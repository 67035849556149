<template>
  <div>
    <b-tabs class="nav-tabs custom-tabs-ce">
      <b-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :title="tab.title"
        :disabled="tab.disabled"
      >
        <div v-if="index === 0" style="max-height: 600px; overflow-x: hidden; overflow-y: auto;">
                <div v-for="(item, index) in itemResumenes" :key="index" class="row mb-3" >
                    <div class="col-12 bordeResumen p-3" style="border-top: none !important;">
                        <div class="row">
                            <div class="col-12">
                                <b>N:</b> {{ item.numero_consulta }} - <b>Fecha:</b> {{ formatDate(item.fecha) }} - <b>Medico:</b> {{ item.medico }}
                            </div>
                        </div>
                        <div class="row mb-2" v-if="item.motivo">
                            <div class="col-12" style="line-height: 1;">
                                <b>Motivo:</b> {{ item.motivo }}
                            </div>
                        </div>
                        <div class="row mb-2" v-if="item.tratamiento">
                            <div class="col-12" style="line-height: 1;">
                                <b>Tratamiento:</b> {{ item.tratamiento }}
                            </div>
                        </div>
                        <div class="row mb-2" v-if="item.enfermedad">
                            <div class="col-12" style="line-height: 1;">
                                <b>Enfermedad:</b> {{ item.enfermedad }}
                            </div>
                        </div>

                    </div>
                </div>
        </div>
        <div v-if="index === 1">
            <b-table
            class="table table-hover"
            :fields="fields"
            :items="listaTabla"
            >
                <template #cell(fecha)="item">
                    {{ formatDate(item.item.fecha) }}
                </template>
                <template #cell(opciones)="item">
                    <i
                    class="nav-icon i-File-Download font-weight-bold text-info cursor-pointer"
                    style="cursor: pointer"
                    @click="openModalPrint(item.item)"
                    ></i>
                </template>
            </b-table>
        </div>
      </b-tab>
    </b-tabs>
    <modalPrint
      :itemService="itemServicio"
      :selectedConsulta="selectedId"
      @open-modal="openModalPrint"
    />
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import modalPrint from './modalPrint.vue';

export default {
  name: 'TabDetalleCE',
  components: {
    modalPrint,
  },
  props: {
    pacienteId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tabs: [
        { title: 'Resumen', disabled: false },
        { title: 'Detallado', disabled: false },
      ],
      fields: [
        {
          key: 'numero', label: 'N°', class: 'text-center', thClass: 'bg-light', thStyle: { width: '7%' },
        },
        {
          key: 'numero_consulta', label: 'N° C.E', class: 'text-center', thClass: 'bg-light', thStyle: { width: '8%' },
        },
        {
          key: 'tipo', label: 'Tipo', class: 'text-center', thClass: 'bg-light', thStyle: { width: '11%' },
        },
        {
          key: 'fecha', label: 'Fecha', class: 'text-center', thClass: 'bg-light', thStyle: { width: '9%' },
        },
        {
          key: 'medico', label: 'Médico', class: 'text-center', thClass: 'bg-light', thStyle: { width: '21%' },
        },
        {
          key: 'opciones', label: '', class: 'text-center', thClass: 'bg-light', thStyle: { width: '4%' },
        },
      ],
      selectedId: null,
      listaTabla: [
      ],
      itemServicio: null,
      itemResumenes: [],
    };
  },
  created() {
    this.getServicioResumen();
    this.listarRegistros();
  },
  methods: {
    async listarRegistros() {
      try {
        const RESPONSE = await axios.get(`/clinic/consultation/patient/historia_clinica/${this.pacienteId}`);
        this.listaTabla = RESPONSE.data.data.sort((a, b) => b.numero - a.numero);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    formatDate(fecha) {
      if (!fecha) return '';
      const [year, month, day] = fecha.split('-');
      return `${day}/${month}/${year}`;
    },
    async getServicioComplementario(item) {
      try {
        const RESPONSE = await axios.get(`clinic/consulta_externa/${item}/get_categoria_servicio_complementarios`);
        this.itemServicio = RESPONSE.data.data.categoria_servicio_complementarios;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    openModalPrint(item) {
      this.selectedId = item;
      this.getServicioComplementario(item.consulta_id).then(() => {
        this.$bvModal.show('modalPrintConsultaExterna');
      });
    },
    async getServicioResumen() {
      try {
        const RESPONSE = await axios.get(`clinic/consultation/patient/historia_resumen/${this.pacienteId}`);
        this.itemResumenes = RESPONSE.data.data.slice(-5);
        this.itemResumenes.sort((a, b) => b.numero - a.numero);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
};
</script>

  <style>
.custom-tabs-ce .nav-tabs {
  background-color: white !important;
  border-bottom: 2px solid #ddd;
}

.custom-tabs-ce .nav-link {
  color: black !important;
  background-color: transparent !important;
}

.custom-tabs-ce .nav-link.active {
  background-color: white !important;
  border-color: #ddd #ddd white;
}
.bordeResumen {
    border: #dee2e6 solid 1px;
    font-size: 11px;
}
</style>

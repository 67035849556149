<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div>
      <div class="row mr-0">
        <div class="col-sm-3">
          <div class="breadcrumb">
            <h1>
              Consulta Externa
              <span class="h4 pl-2" v-if="consulta">
                N°: {{consulta.numero}}
              </span>
            </h1>
          </div>
        </div>
        <transition name="fade">
          <div class="col-sm-6 pl-0 navbar-reubicado" v-if="paciente">
            <div class="row mx-0">
              <div class="col-sm-3 py-2 bg-sky-light text-white border-left border-white">
                <b>Edad:</b> {{ paciente.edad }} años
              </div>
              <div v-if="paciente.tipo_seguro === 'Conjunto'"
                class="col-sm-4 px-1 py-2 bg-sky-light text-white"
              >
                <b>Seguro:</b> {{ paciente.cobertura }}
                <span v-if="paciente.tipo_familiar">
                  ({{ paciente.tipo_familiar }})
                </span>
              </div>
              <div
                v-if="paciente.numero_poliza || paciente.cod_asegurado"
                class="col-sm-3 px-1 py-2 bg-sky-light text-white"
              >
                <template v-if="paciente.numero_poliza">
                  <b>N° Poliza: </b> {{ paciente.numero_poliza }}
                </template>
                <template v-else>
                  <b>N° Cod.:</b>{{paciente.cod_asegurado}}
                </template>
              </div>
            </div>
          </div>
        </transition>
        <div v-if="showButton" class="col-sm-3">
          <div class="row">
            <div class="col-sm-7 text-right pr-0">
              <label class="col-form-label">La consulta se cerrara en:</label>
            </div>
            <div class="col-sm-5">
              <input
                type="text"
                class="form-control text-center text-20 text-danger border-danger"
                v-model="tiempoCierre"
                readonly
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-sm-12">
          <div class="card text-left">
            <div class="card-header">
              <div class="row">
                <div class="col-sm-5">
                  <router-link :to="{ name: 'consulta-externa-paciente',
                    params: {id: (encrypt(pacienteDto.medico_id))}}">
                    <button class="btn btn-white border border-light mr-2">
                      Regresar
                    </button>
                  </router-link>
                  <button type="submit"
                    class="btn btn-dark mr-2"
                    @click="storeConsultaExterna(false)"
                  >
                    Guardar
                  </button>
                  <button class="btn bg-custom-green mr-2"
                    @click="storeConsultaExterna(true)">Guardar y Cerrar</button>
                </div>
                <div class="col-sm-4 info-tratamiento-paciente" v-show="showReconsulta">
                  <div class="d-flex p-2 background-title">
                    <div class="font-weight-bold">
                      <span style="white-space: nowrap;">
                        El paciente tiene un tratamiento en proceso
                        <strong style="color: red; cursor: pointer;"
                          @click="openModalTratamientoProceso"> Ver </strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6">
                  <div class="row">
                <div class="col-sm-12">
                  <div class="row pt-3">
                    <div class="col d-flex"><strong>Fecha: </strong>
                      <div class="px-3">
                        {{ pacienteDto.fecha }} - Iniciado {{ timeFormatHHMM(pacienteDto.hora) }}
                      </div>
                    </div>
                  </div>
                  <div class="row pb-3" v-if="paciente">
                    <div class="col-12"><strong>Paciente:</strong>
                      {{ paciente.full_name }}
                      ({{ paciente.edad }} años) - N° HC: {{ paciente.numero }}
                    </div>
                  </div>
                </div>
              </div>
                  <div class="form-inline pt-2">
                    <button class="btn bg-custom-green mr-2"
                    v-if="tipoConsulta === 1"
                    :disabled="consultaCierre ? false : true"
                    :style="{ cursor: 'default' }">
                      Consulta
                    </button>
                    <button class="btn btnOrange mr-2"
                    :style="{ cursor: 'default' }"
                    v-if="tipoConsulta === 2">
                      Reconsulta
                    </button>
                    <button class="btn btn-default mr-2" v-if="hasFuncionalidadEnfermeria"
                      :class="signos_vitales && signos_vitales.peso ?  'btn btn-success' : 'btn btn-secondary'"
                      @click="openModalSignosVitales"
                      >
                      Signos Vitales
                    </button>
                    <button class="btn mr-2"
                    :class="hasValidAntecedentes() ? 'btn-success': 'btn btn-secondary'"
                    @click="openModalAntecedente"
                    >
                      Antecedentes
                    </button>
                  </div>
                  <div
                    v-if="cantidad_diagnostico >= 1"
                    class="form-group mt-2"
                  >
                  <b-card class="border border-light">
                    <b>Diagnóstico: </b>
                    <div class="row mt-1 mb-2">
                      <ComboDiagnostic
                      :id="'comboDiagnostic-1'"
                      :key="'comboDiagnostic-1'"
                      :diagnostico-inicial="diagnostico1Selected"
                      @addDiagnostico="(diagnostico) => guardarDiagnosticoSeleccionado(diagnostico, 1)"
                    />
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <input
                          id="inputDiagnostico"
                          v-model="descripcionDiagnostico1"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </b-card>
                  </div>
                  <div
                    v-if="cantidad_diagnostico >= 3"
                    class="form-group mt-4"
                  >
                  <b-card class="border border-light">
                    <b>Diagnóstico 3: </b>
                    <div class="row mt-1 mb-2">
                      <ComboDiagnostic
                      :id="'comboDiagnostic-3'"
                      :key="'comboDiagnostic-3'"
                      :diagnostico-inicial="diagnostico3Selected"
                      @addDiagnostico="(diagnostico) => guardarDiagnosticoSeleccionado(diagnostico, 3)"
                    />
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <input
                          id="inputDiagnostico"
                          v-model="descripcionDiagnostico3"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </b-card>
                  </div>
                  <div v-if="parametrosVisibles[0]" class="form-group pt-2">
                    <b>
                      <font size="2">
                        {{ parametrosVisibles[0].parametro ? parametrosVisibles[0].valor : 'S/N' }}
                        <span v-if="parametrosVisibles[0].obligatorio" style="color: red"> * </span>:
                      </font>
                    </b>
                    <textarea
                      :name="parametrosVisibles[0].parametro"
                      :id="parametrosVisibles[0].parametro"
                      cols="10"
                      rows="3"
                      class="form-control textarea-ce"
                      :value="getModel(parametrosVisibles[0])"
                      @input="autoExpand($event); setModel(parametrosVisibles[0], $event.target.value)"
                    ></textarea>
                  </div>
                  <div v-if="parametrosVisibles[1]" class="form-group pt-2">
                    <b>
                      <font size="2">
                        {{ parametrosVisibles[1].parametro ? parametrosVisibles[1].valor : 'S/N' }}
                        <span v-if="parametrosVisibles[1].obligatorio" style="color: red"> * </span>:
                      </font>
                    </b>
                    <textarea
                      :name="parametrosVisibles[1].parametro"
                      :id="parametrosVisibles[1].parametro"
                      cols="10"
                      rows="2"
                      class="form-control textarea-ce"
                      :value="getModel(parametrosVisibles[1])"
                      @input="autoExpand($event); setModel(parametrosVisibles[1], $event.target.value)"
                    ></textarea>
                  </div>
                  <div v-if="parametrosVisibles[2]" class="form-group pt-2">
                    <b>
                      <font size="2">
                        {{ parametrosVisibles[2].parametro ? parametrosVisibles[2].valor : 'S/N' }}
                        <span v-if="parametrosVisibles[2].obligatorio" style="color: red"> * </span>:
                      </font>
                    </b>
                    <textarea
                      :name="parametrosVisibles[2].parametro"
                      :id="parametrosVisibles[2].parametro"
                      cols="10"
                      rows="2"
                      class="form-control textarea-ce"
                      :value="getModel(parametrosVisibles[2])"
                      @input="autoExpand($event); setModel(parametrosVisibles[2], $event.target.value)"
                    ></textarea>
                  </div>
                  <div v-if="parametrosVisibles[3]" class="form-group pt-2">
                    <b>
                      <font size="2">
                        {{ parametrosVisibles[3].parametro ? parametrosVisibles[3].valor : 'S/N' }}
                        <span v-if="parametrosVisibles[3].obligatorio" style="color: red"> * </span>:
                      </font>
                    </b>
                    <textarea
                      :name="parametrosVisibles[3].parametro"
                      :id="parametrosVisibles[3].parametro"
                      cols="10"
                      rows="3"
                      class="form-control textarea-ce"
                      :value="getModel(parametrosVisibles[3])"
                      @input="autoExpand($event); setModel(parametrosVisibles[3], $event.target.value)"
                    ></textarea>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div
                    v-if="cantidad_diagnostico >= 2"
                    class="form-group mt-4"
                  >
                  <b-card class="border border-light">
                    <b>Diagnóstico 2: </b>
                    <div class="row mt-1 mb-2">
                      <ComboDiagnostic
                      :id="'comboDiagnostic-2'"
                      :key="'comboDiagnostic-2'"
                      :diagnostico-inicial="diagnostico2Selected"
                      @addDiagnostico="(diagnostico) => guardarDiagnosticoSeleccionado(diagnostico, 2)"/>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <input
                          id="inputDiagnostico"
                          v-model="descripcionDiagnostico2"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </b-card>
                  </div>
                  <div
                    v-if="cantidad_diagnostico >= 4"
                    class="form-group mt-4"
                  >
                  <b-card class="border border-light">
                    <b>Diagnóstico 4: </b>
                    <div class="row mt-1 mb-2">
                      <ComboDiagnostic
                      :id="'comboDiagnostic-4'"
                      :key="'comboDiagnostic-4'"
                      :diagnostico-inicial="diagnostico4Selected"
                      @addDiagnostico="(diagnostico) => guardarDiagnosticoSeleccionado(diagnostico, 4)"
                    />
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <input
                          id="inputDiagnostico"
                          v-model="descripcionDiagnostico4"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </b-card>
                  </div>
                  <TabDetalleCE :pacienteId="pacienteDto.paciente_id"></TabDetalleCE>
                  <div class="form-group mt-4" style="background: #dee2e6;" v-show="showReconsulta">
                    <div class="card-body border border-light">
                      <div class="form-inline pb-3">
                        <div class="pr-4" style="color: green;">
                          Resultado de Servicios Complementarios:
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <button class="btn btn-secondary mr-2">Laboratorio</button>
                          <button class="btn btn-secondary mr-2">Rayos X</button>
                          <button class="btn btn-secondary mr-2">Ecografia</button>
                          <button class="btn btn-secondary mr-2">Fisioterapia</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 pt-3">
                  <TheServiciosComplementarios
                    :reconsulta="tipoConsulta === 2 ? false : true"
                    :title="titleServicioComplementario"
                    :itemList="parametroServicioComplementarioList"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TheModalAntecedente
    v-if="pacienteDto"
    :internacion="false"
    :idPaciente="pacienteDto.paciente_id"
    @addAntecedentes="addAntecedentes($event)"/>
    <TheModalTratamientoProceso v-if="consulta"/>
    <TheModaSignoVitales
    :medico="medico.nombre"
     @addSignosVitales="addSignosVitales($event)"
     :por_enfermeria="por_enfermeria"
    />
    <ModalHistorialClinico :pacienteId="pacienteDto.paciente_id"/>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import {
  mapActions,
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';
import { mapFields } from 'vuex-map-fields';
import moment from 'moment';
import TheServiciosComplementarios from '../components/TheServiciosComplementarios.vue';
import TheModalAntecedente from '../components/TheModalAntecedente.vue';
import TheModalTratamientoProceso from '../components/TheModalTratamientoProceso.vue';
import TheModaSignoVitales from '../components/TheModaSignoVitales.vue';
import ModalHistorialClinico from '../components/TheModalHistorialClinico.vue';
import ComboDiagnostic from '../../tratamiento/components/TheDiagnostic.vue';
import TabDetalleCE from '../components/TabDetalleCE.vue';

export default {
  name: 'Consulta-Externa',
  data() {
    return {
      tipoConsulta: 1,
      showEnfermeria: false,
      showReconsulta: false,
      isLoading: true,
      pacienteDto: null,
      titleMotivoConsulta: 'S/N',
      titleEnfermedadActual: 'S/N',
      titleExamenFisico: 'S/N',
      titleTratamiento: 'S/N',
      titleServicioComplementario: 'S/N',
      parametroServicioComplementarioList: [],
      tiempoCierre: '59:00', // funciona como mm:ss
      timerInterval: null,
      showButton: false,
      listaClasificacionCIEFiltrada: [],
      clasificacionCIESeleccionado: null,
      signosVitales: [],
      parametro_consulta_textos: [],
      parametrosUnificados: [],
      listaAntecedentes: {},
    };
  },
  async created() {
    const { params } = this.$route;
    this.getParametrosConsultaExterna();
    await this.edit(atob(params.id));
    this.pacienteDto = JSON.parse(localStorage.getItem('Paciente-Consulta-Externa'));
    this.tipoConsulta = this.consulta.tipo_consulta_id;
    this.reconsultation(this.tipoConsulta);
    this.isLoading = false;
    this.timerInterval = setInterval(() => {
      const a = this.tiempoCierre.split(':');
      const totalSeconds = ((+a[0]) * 60 + (+a[1])) - 1;
      const mm = Math.floor(totalSeconds / 60);
      let ss = totalSeconds % 60;
      if (ss < 10) {
        ss = `0${ss}`;
      }
      this.tiempoCierre = `${mm}:${ss}`;
      if (totalSeconds === 0) {
        clearInterval(this.timerInterval);
      }
    }, 1000);
    if (this.signos_vitales) {
      this.cargarDataSignoVitales(this.signos_vitales);
    }
  },
  beforeDestroy() {
    this.resetState();
    clearInterval(this.timerInterval);
  },
  components: {
    TheServiciosComplementarios,
    TheModalAntecedente,
    TheModalTratamientoProceso,
    TheModaSignoVitales,
    ModalHistorialClinico,
    ComboDiagnostic,
    TabDetalleCE,
  },
  methods: {
    async getParametrosConsultaExterna() {
      try {
        const response = await axios.get('/clinic/servicio_complementario/nombre_parametro_servicio_consulta/create');
        const parametro = response.data.data.nombre_parametro_servicio_consulta;
        const parametro2 = response.data.data.nombre_parametro_servicio_complementario;
        this.parametrosUnificados = parametro.map((servicio) => {
          const texto = this.parametro_consulta_textos.find((item) => item.nombre === servicio.parametro);
          return {
            id: servicio.id,
            parametro_consulta_id: texto ? texto.id : null,
            parametro: servicio.parametro,
            valor: servicio.valor,
            obligatorio: servicio.obligatorio,
            visible: servicio.visible,
            cantidad_caracteres: servicio.cantidad_caracteres,
          };
        });
        this.parametrosUnificados.forEach((item) => {
          if (item.parametro === 'motivo_consulta') {
            this.titleMotivoConsulta = item.valor;
          } else if (item.parametro === 'enfermedad_actual') {
            this.titleEnfermedadActual = item.valor;
          } else if (item.parametro === 'examen_fisico') {
            this.titleExamenFisico = item.valor;
          } else if (item.parametro === 'tratamiento') {
            this.titleTratamiento = item.valor;
          } else if (item.parametro === 'servicio_complementario') {
            this.titleServicioComplementario = item.valor;
          }
          this.getModel(item);
        });
        if (parametro2.length !== 0) {
          this.parametroServicioComplementarioList = parametro2;
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    openModalAntecedente() {
      this.$bvModal.show('theModalAntecedente');
    },
    async openModalTratamientoProceso() {
      const OK_GET_DATA_RECONSULTA = await this.getDataReconsultaTratamiento(this.consulta);
      if (OK_GET_DATA_RECONSULTA) {
        this.$bvModal.show('theModalTratamientoProceso');
      }
    },
    openModalSignosVitales() {
      this.$bvModal.show('modal-signo-vitales');
    },
    openModalHistorialClinico() {
      this.$bvModal.show('TheModalHistorialClinico', {
        props: {
          pacienteId: this.pacienteDto.paciente_id,
        },
      });
    },
    reconsultation(tipo) {
      if (tipo === 2) {
        this.showReconsulta = true;
      }
    },
    timeFormatHHMM(hora) {
      return moment(hora, 'HH:mm:ss').format('HH:mm');
    },
    ...mapActions('ConsultaExternaEdit', [
      'edit',
      'resetState',
      'create',
      'store',
    ]),
    ...mapActions('theModalTratamientoProceso', [
      'getDataReconsultaTratamiento',
    ]),
    ...mapMutations('theModalSignosVitales', ['cargarDataSignoVitales']),

    async storeConsultaExterna(_cierre) {
      if (this.validateAndCleanParameters()) {
        const DTO = {
          paciente_id: this.paciente.id,
          medico_id: this.pacienteDto.medico_id,
          venta_id: this.pacienteDto.venta_id,
          cierre: _cierre,
          servicios_complementarios: this.clearParamServicioComplementario(),
          signos_vitales: this.signosVitales[0],
          antecedente: {
            id: this.listaAntecedentes.id,
            tipo_dieta_id: this.listaAntecedentes.tipoDietaSelected ? this.listaAntecedentes.tipoDietaSelected.id : null,
            tipo_vivienda_id: this.listaAntecedentes.tipoViviendaSelected ? this.listaAntecedentes.tipoViviendaSelected.id : null,
            tipo_tabaco_id: this.listaAntecedentes.tipoTabacoSelected ? this.listaAntecedentes.tipoTabacoSelected.id : null,
            tipo_alcohol_id: this.listaAntecedentes.tipoAlcohosSelected ? this.listaAntecedentes.tipoAlcohosSelected.id : null,
            f_inicio_tabaco: this.formattedDateAntecedent(this.listaAntecedentes.fechaInicio),
            f_inicio_alcohol: this.formattedDateAntecedent(this.listaAntecedentes.fechaInicio2),
            tipo_medicamento_id: this.listaAntecedentes.tipoMedicamentoSelected ? this.listaAntecedentes.tipoMedicamentoSelected.id : null,
            tipo_actividad_fisica_id: this.listaAntecedentes.tipoActividadFisicaSelected ? this.listaAntecedentes.tipoActividadFisicaSelected.id : null,
            tipo_ocupacion_id: this.listaAntecedentes.tipoOcupacionSelected ? this.listaAntecedentes.tipoOcupacionSelected.id : null,
            tipo_psicosocial_id: this.listaAntecedentes.tipoPsicosocialSelected ? this.listaAntecedentes.tipoPsicosocialSelected.id : null,
            cardiovasculares: this.listaAntecedentes.cardiovasculares,
            alergias: this.listaAntecedentes.alergias,
            otros: this.listaAntecedentes.descripcionOtros,
          },
          patologias_antecedente: this.obtenerPatologiasAntecedente(),
          cirugias_antecedente: this.obtenerCirugiasAntecedente(),
        };
        const RESULT_OK = await this.store(DTO);
        if (RESULT_OK) {
          if (_cierre) {
            this.$router.push({ name: 'consulta-externa-paciente', params: { id: this.encrypt(this.pacienteDto.medico_id), stateSelected: this.encrypt(2) } });
          } else {
            this.$router.push({ name: 'consulta-externa-index' });
          }
        }
      }
    },
    encrypt(id) {
      return btoa(id);
    },
    clearParamServicioComplementario() {
      this.lisServiciosComplementarios.forEach((e, index) => {
        delete this.lisServiciosComplementarios[index].numero;
        delete this.lisServiciosComplementarios[index].categoria;
        delete this.lisServiciosComplementarios[index].numero_interno;
      });
      return this.lisServiciosComplementarios;
    },
    obtenerPatologiasAntecedente() {
      return this.listaAntecedentes.patologias.length > 0
        ? this.listaAntecedentes.patologias.map((patologia) => ({
          id: patologia.id || null,
          descripcion: patologia.descripcion,
          estado: patologia.tipoPatologiaSelected?.estado
            ? patologia.tipoPatologiaSelected.estado === '1' || patologia.tipoPatologiaSelected.estado === true
            : false,
          tipo_patologia_id: patologia.tipoPatologiaSelected?.id || null,
        }))
        : [];
    },

    obtenerCirugiasAntecedente() {
      return this.listaAntecedentes.cirugias.length > 0
        ? this.listaAntecedentes.cirugias.map((cirugia) => ({
          id: cirugia.id || null,
          descripcion: cirugia.descripcion,
          estado: cirugia.tipoCirugiaSelected?.estado
            ? cirugia.tipoCirugiaSelected.estado === '1' || cirugia.tipoCirugiaSelected.estado === true
            : false,
          tipo_cirugia_id: cirugia.tipoCirugiaSelected?.id || null,
        }))
        : [];
    },
    guardarDiagnosticoSeleccionado(diagnostico, indice) {
      if (diagnostico) {
        this.clasificacionCIESeleccionado = diagnostico;
        this[`diagnostico${indice}Selected`] = this.clasificacionCIESeleccionado;
        this[`sub_clasificacion_cie${indice}`] = this[`diagnostico${indice}Selected`].id_sub;
      } else {
        this.clasificacionCIESeleccionado = null;
        this[`diagnostico${indice}Selected`] = null;
        this[`sub_clasificacion_cie${indice}`] = null;
      }
    },
    addSignosVitales(data) {
      this.signosVitales = [];
      const obj = {
        id: data.id,
        talla: data.talla,
        peso: data.peso,
        temperatura: data.oral,
        indice_masa_corporal: data.indiceCorporal,
        pulso: null,
        frecuencia_respiratoria: data.frecuenciaRespiratoria,
        frecuencia_cardiaca: data.frecuenciaCardiaca,
        saturacion_oxigeno: data.saturacionOxigeno,
        presion_arterial_sistolica: data.sistolica,
        presion_arterial_diastolica: data.diastolica,
      };
      this.signosVitales.push(obj);
    },
    getModel(item) {
      if (item.parametro === 'motivo_consulta') {
        return this.motivoConsulta;
      }
      if (item.parametro === 'enfermedad_actual') {
        return this.enfermedadActual;
      }
      if (item.parametro === 'examen_fisico') {
        return this.examenFisico;
      }
      if (item.parametro === 'tratamiento') {
        return this.tratamiento;
      }
      return '';
    },
    setModel(item, value) {
      if (item.parametro === 'motivo_consulta') {
        this.motivoConsulta = value;
      } else if (item.parametro === 'enfermedad_actual') {
        this.enfermedadActual = value;
      } else if (item.parametro === 'examen_fisico') {
        this.examenFisico = value;
      } else if (item.parametro === 'tratamiento') {
        this.tratamiento = value;
      }
    },
    autoExpand(event) {
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    },
    validateAndCleanParameters() {
      let isValid = true;
      this.parametrosUnificados.forEach((item) => {
        if (item.visible === 1 && item.obligatorio === 1 && item.parametro !== 'servicio_complementario') {
          const value = this.getModel(item);
          if (!value || value.trim() === '') {
            util.showNotify(`${item.valor} es obligatorio y no puede estar vacío`, 'error');
            isValid = false;
          }
        }

        if (item.visible === 0) {
          this.setModel(item, '');
        }
      });

      return isValid;
    },
    isParameterVisible(parametro) {
      // eslint-disable-next-line no-shadow
      const item = this.parametrosVisibles.find((item) => item.parametro === parametro);
      return item ? item.visible === 1 : false;
    },
    isFieldRequired(parametro) {
      // eslint-disable-next-line no-shadow
      const item = this.parametrosVisibles.find((item) => item.parametro === parametro);
      return item ? item.obligatorio === 1 : false;
    },
    formattedDateAntecedent(fecha) {
      if (!fecha) return null;

      const dateObj = new Date(fecha);

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(dateObj.getTime())) return null; // Validar que la fecha sea válida

      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // +1 porque los meses van de 0 a 11
      const day = String(dateObj.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`; // Formato "YYYY-MM-DD"
    },
    openModalAntecedentes() {
      this.$bvModal.show('theModalAntecedente');
    },
    addAntecedentes(data) {
      this.listaAntecedentes = data;
      this.hasValidAntecedentes();
    },
    hasValidAntecedentes() {
      return Object.keys(this.listaAntecedentes).some((key) => {
        if (key === 'fechaInicio' || key === 'fechaInicio2' || key === 'id') {
          return false;
        }
        const value = this.listaAntecedentes[key];
        if (!value || (Array.isArray(value) && value.length === 0)) {
          return false;
        }

        return true;
      });
    },
  },
  computed: {
    ...mapState('ConsultaExternaEdit', [
      'nombreDiagnostico',
      'consultaCierre',
      'antecedente',
      'consulta',
      'paciente',
    ]),
    ...mapFields('ConsultaExternaEdit', [
      'diagnostico1Selected',
      'diagnostico2Selected',
      'diagnostico3Selected',
      'diagnostico4Selected',
      'tratamiento',
      'examenFisico',
      'enfermedadActual',
      'motivoConsulta',
      'descripcionDiagnostico1',
      'descripcionDiagnostico2',
      'descripcionDiagnostico3',
      'descripcionDiagnostico4',
      'subclasificacion_cie1_id',
      'subclasificacion_cie2_id',
      'subclasificacion_cie3_id',
      'subclasificacion_cie4_id',
      'cantidad_diagnostico',
      'lisServiciosComplementarios',
      'signos_vitales',
      'medico',
      'por_enfermeria',
    ]),
    ...mapGetters('main', [
      'hasFuncionalidadEnfermeria',
    ]),
    parametrosVisibles() {
      return this.parametrosUnificados.filter((item) => {
        if (item.parametro === 'tratamiento') {
          return this.cantidad_diagnostico <= 2 && item.visible === 1;
        }
        if (item.parametro === 'servicio_complementario') {
          return false;
        }
        return item.visible === 1;
      });
    },
  },
};
</script>

<style scoped>
.btnOrange {
  color: white;
  background-color: #F4B375;
}
.info-tratamiento-paciente {
  position: relative;
  top: -48px;
  height: 60px;
  right: 60px;
}
.background-title {
  background: yellow;
}
.bg-sky-light
{
  background:#a6d2eb;
}
.navbar-reubicado
{
  transform: translate(18%, -32px);
  white-space: nowrap;
}
.textarea-ce {
  border:1px solid black;
}
</style>
